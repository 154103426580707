import React from 'react';

import Typed from "typed.js"

import Button from '../components/Button';
import Card from '../components/Card';
import CustomerCard from '../components/CustomerCard';
import LabelText from '../components/LabelText';
import Layout from '../components/layout/Layout';
import SplitSection from '../components/SplitSection';

import customerData from '../data/customer-data';

import phone from '../assets/phone.jpg';
import questions from '../assets/questions.png';
import rdv from '../assets/rdv.png';
import done from '../assets/done.png';
import solving from '../assets/solving.png';
import location from '../assets/location.png';
import checklist from '../assets/checklist.png';
import whyUs from '../assets/whyUs.png';

import "./../css/global.css";

const Index = () => {

  const element = React.useRef(null);

  const onClick = () => {
    navigator.clipboard.writeText("06 50 71 21 18")
  }

  React.useEffect(()=>{
    if(element.current){
      const options = {
        strings: [
          "Réparation d'ordinateur portable ou fix",
          'Installer Windows ou MacOS',
          'Optimiser les performances de votre ordinateur',
          "Analyse puis installation d'un anti-virus",
          'Réparer votre IPhone'
        ],
        typeSpeed: 100,
        backSpeed: 40,
        smartBackspace: true,
        loop: true
      };
      const typedString = new Typed(element.current, options);
  
      return () => {
        typedString.destroy();
      };
    };
  }, []);

  return(
    <Layout>
      <section className="pt-20" id="home">
        <div className="container mx-auto px-8 lg:flex">
          <div className="text-center lg:text-left lg:w-1/2">
            <h1 className="text-4xl lg:text-5xl xl:text-6xl font-bold leading-none title">
              <span ref={element}></span>
            </h1>
            <p className="text-xl lg:text-2xl mt-6 font-light">
              Osez réparer, installer, optimiser ou demander conseils sur votre matériels informatiques chez DepanGouss un professionnel à un prix avantageux et en toute transparence.
            </p>
            <p className="mt-8 md:mt-12">
              <Button size="lg" onClick={() => onClick()}>
                06 50 71 21 18
              </Button>
            </p>
            <br/>
            <p className="mt-4 text-gray-600 devis mb-10">Le devis est gratuit_</p>
          </div>
          <div className="lg:w-1/2 lg:ml-16">
            <img src={phone} alt="phone"/>
          </div>
        </div>
      </section>
      <section id="how" className="py-20 lg:pb-40 lg:pt-48">
        <div className="container mx-auto text-center">
          <h2 className="text-3xl lg:text-5xl font-semibold">Comment ça marche ?</h2>
          <div className="flex flex-col sm:flex-row sm:-mx-3 mt-12">
            <div className="flex-1 px-3">
              <Card className="mb-8">
                <div>
                  <img src={questions} alt="questions"/>
                </div>
                <p className="font-semibold text-xl">Besoin d'aide</p>
                <p className="mt-4">
                  Vous avez une panne sur un appareil higth-tech ? Votre écran est cassé ?
                  Vous avez besoins d’aide pour installer Windows ? Vous avez sonnez à la <strong>bonne porte</strong> !
                </p>
              </Card>
            </div>
            <div className="flex-1 px-3">
              <Card className="mb-8">
                <div>
                  <img src={rdv} alt="rdv"/>
                </div>
                <p className="font-semibold text-xl">Prendre RDV</p>
                <p className="mt-4">
                  Appelez gratuitement le <br/>
                  <strong>06 50 71 21 18</strong> pour savoir quand et ou
                  votre appareil sera pris en charge, et estimé le devis <strong>gratuitement</strong> !
                </p>
              </Card>
            </div>
            <div className="flex-1 px-3">
              <Card className="mb-8">
                <div>
                  <img src={solving} alt="solving"/>
                </div>
                <p className="font-semibold text-xl">Solutions</p>
                <p className="mt-4">
                  Vous bénéficierez d’une réel expertise et d’un service à
                  l’écoute de vos demandes pour résoudre au mieux votre problème.
                </p>
              </Card>
            </div>
            <div className="flex-1 px-3">
              <Card className="mb-8">
                <div>
                  <img src={done} alt="done"/>
                </div>
                <p className="font-semibold text-xl">Vous êtes satisfait(e)</p>
                <p className="mt-4">
                  Nous somme garant de votre <strong>satisfaction</strong>.
                  Vous n'aurez plus de soucis avec vos appareils.
                </p>
              </Card>
            </div>
          </div>
        </div>
      </section>
      <SplitSection
        id="needs"
        primarySlot={
          <div className="lg:pr-32 xl:pr-48">
            <h3 className="text-3xl font-semibold leading-tight">Besoins d'un expert</h3>
            <p className="mt-8 text-xl font-light leading-relaxed">
              Situé dans la commune de <strong>Goussainville</strong> dans le Val d'Oise, demandez nos services par téléphone au <br/>
              <strong>06 50 71 21 18</strong> ou par mail à <strong>depanne.informatique95@gmail.com</strong>
              <br/>
              Nous somme disponible et à votre écoute 6 jours sur 7 (fermé le dimanche) de 9h à 20h pour des réparations et des dépannages informatiques.
            </p>
          </div>
        }
        secondarySlot={<img src={location} alt="phone"/>}
      />
      <SplitSection
        reverseOrder
        primarySlot={
          <div className="lg:pl-32 xl:pl-48">
            <h3 className="text-3xl font-semibold leading-tight">
              Nos services
            </h3>
            <p className="mt-8 text-xl font-light leading-relaxed">
              <li>Réparation d'ordinateurs toutes marques</li>
              <li>Réparation de téléphones (uniquement Apple)</li>
              <li>Installation de logiciels et de matériels</li>
              <li>Installation de systèmes d'exploitations MacOS ou Windows</li>
              <li>Optimisation de PC lent</li>
              <li>Mise à jour et nettoyage complet</li>
              <li>Récupération de données perdu ou supprimées</li>
              <li>Supression de virus et de logiciels malveillants</li>
            </p>
          </div>
        }
        secondarySlot={<img src={checklist} alt="phone"/>}
      />
      <SplitSection
        primarySlot={
          <div className="lg:pr-32 xl:pr-48">
            <h3 className="text-3xl font-semibold leading-tight">
              Pourquoi nous ?
            </h3>
            <p className="mt-8 text-xl font-light leading-relaxed">
              <li>Prix abordable</li>
              <li>Devis gratuit</li>
              <li>Transparence total</li>
              <li>Satisfait ou refait</li>
              <li>Déplacement à domicile dans certain cas</li>
            </p>
          </div>
        }
        secondarySlot={<img src={whyUs} alt="phone"/>}
      />
      <section id="testimonials" className="py-20 lg:py-40">
        <div className="container mx-auto">
          <LabelText className="mb-8 text-gray-600 text-center">Ils donnents leurs avis</LabelText>
          <div className="flex flex-col md:flex-row md:-mx-3">
            {customerData.map(customer => (
              <div key={customer.customerName} className="flex-1 px-3 testimonialsWrapper">
                <CustomerCard customer={customer} />
              </div>
            ))}
          </div>
        </div>
      </section>
      <section className="container mx-auto my-20 py-24 bg-gray-200 rounded-lg text-center">
        <h3 className="text-5xl font-semibold">Convaincu de notre expertise ?</h3>
        <p className="mt-8 text-xl font-light">
          Alors n'hésitez à nous contacter au numéro ci-dessous pour prendre rendez-vous et planifier votre demande.
        </p>
        <p className="mt-8">
          <Button size="xl" onClick={() => onClick()}>06 50 71 21 18</Button>
        </p>
      </section>
    </Layout>
  );
};

export default Index;
