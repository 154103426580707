export default [
  {
    title: 'Réparation rapide avec devis gratuit',
    content:
      "J'ai été très satisfaite de la prestation de ma panne sur mon MacBook Air. De plus le devis est gratuit, la réparation à été relativement très rapide, et les tarifs sont très abordables. Je recommande vivement!",
    customerName: 'Imen G.',
    customerTitle: 'Habitant de Goussainville',
  },
  {
    title: 'Professionel et sérieux',
    content:
      "Le changement de mon écran et son contour de mon Acer Nitro 5 à été changer avec précision et professionnalisme. Rien à dire sur la qualité des pièces. J'ai été très satifsait de la prestation. Je me redirigerais vers lui.",
    customerName: 'Dennis J.',
    customerTitle: 'Habitant de Goussainville',
  },
  {
    title: 'Encore une bonne expérience',
    content:
      "Pour la deuxième fois, j'ai fait réparér un PC chez lui de la marque HP pour mon fils. Toujours satisfait de la prestation et de son détails, et surtout du tarif. Encore merci pour tout! Il est top.",
    customerName: 'Pascal D.',
    customerTitle: 'Habitant du Thillay',
  }
];
